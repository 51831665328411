import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Icon, Modal, ContactForm } from 'components';

const ContactSection = () => {
  const { t } = useTranslation();
  const [openContactModal, setOpenContactModal] = useState(false);

  return (
    <StyledContactSection className="contact-section">
      <div className="contact-section-wrapper">
        <div className="contact-section-container">
          <div className="contact-section__title-block">
            <Icon className="contact-section__icon" name="contact-us" />
            <h4 className="contact-section__title">{t('home_page.questions')}</h4>
          </div>
          <Button
            className="contact-section__button"
            primary
            onClick={() => setOpenContactModal(true)}
            data-cy="button_contact_us">
            {t('common.contact_us')}
          </Button>
        </div>
      </div>
      <StyledModal
        className="contact-us-modal"
        open={openContactModal}
        onClose={() => setOpenContactModal(false)}>
        <ContactForm source="Main Page" onSubmit={() => setOpenContactModal(false)} />
      </StyledModal>
    </StyledContactSection>
  );
};

const StyledContactSection = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100px;
  padding: 0 16px;
  .contact-section-wrapper {
    flex: 1;
    max-width: 1334px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: ${props => props.theme.colors.lightGray};
      position: absolute;
    }
    &:before {
      top: 0;
      left: 0;
    }
    &:after {
      bottom: 0;
      left: 0;
    }
  }
  .contact-section-container {
    flex: 1;
    max-width: 1025px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-section {
    &__icon {
      margin: 0 28px 0 0;
      flex-shrink: 0;
    }
    &__title-block {
      margin: 0 28px 0 0;
    }
    &__title {
      font-size: 18px;
      line-height: 24px;
      font-weight: normal;
      margin: 0;
      color: ${props => props.theme.colors.black};
    }
    &__title-block {
      display: flex;
      align-items: center;
    }
    &__button {
      width: 140px;
      flex-shrink: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .contact-section {
      &__title-block {
        margin: 0 38px 0 0;
      }
      &__title {
        text-align: center;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    min-height: auto;
    padding: 0 16px;
    .contact-section {
      &__title-block {
        padding: 0 0 33px;
      }
      &__title-block {
        margin: 0;
      }
      &__title {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
      }
      &__button {
        width: 100%;
        height: 40px;
        font-size: 12px;
        line-height: 24px;
      }
      &__icon {
        margin: 0px 16px 0 0;
      }
    }
    .contact-section-wrapper {
      padding: 28px 0;
    }
    .contact-section-container {
      flex-direction: column;
    }
  }
`;

const StyledModal = styled(Modal)`
  &.contact-us-modal {
    .contact-form {
      margin: 0 10px 12px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    &.contact-us-modal {
      .contact-form {
        margin: 0;
      }
    }
  }
`;

export default ContactSection;
